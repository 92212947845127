import { ILogger } from '@/bridge/ILogger';
import { IMetrics } from '@/bridge/IMetrics';
import { ISessionManager } from '@/bridge/ISessionManager';
import { Operation } from '@/bridge/types/MetricTypes';
import { SessionState, PlatformType } from '@/bridge/types/SoloRTCChannelTypes';
import { WSBrokerService } from '@/core/wsbroker/WSBrokerService';
import { IAuthenticateRequest } from '@/core/wsbroker/types';
import { WsError, WsErrorCodes, WsErrorTypes } from '@/bridge/WsError';
import { IDevice } from '@/bridge/IDevice';
import { CryptoUtils } from '@/helpers/crypto-utils';
import { IAuthRelativePath } from '@/bridge/routes/AuthRoutes';
import { SessionDetails } from '@/bridge/types/SessionTypes';
import { ClientErrorCode } from '@/bridge/types/ErrorTypes';
import { IRegion } from '@bridge/types/RegionTypes';
import { RegionResolver } from '@core/region/RegionResolver';
import { CoreFactory } from '@/bridge/factory/CoreFactory';
import { WarpDriveExternalNativeAuthUrlBuilder } from './authUrls/WarpDriveExternalNativeAuthUrlBuilder';

interface AuthenticationProps {
  wsBrokerClient: WSBrokerService;
  sessionManager: ISessionManager;
  metrics: IMetrics;
  logger: ILogger;
  device: IDevice;
}

const rtcchannel = CoreFactory.getRTCChannel();
export class Authentication {
  private readonly wsBrokerClient: WSBrokerService;
  private readonly sessionManager: ISessionManager;
  private readonly metrics: IMetrics;
  private readonly logger: ILogger;
  private readonly device: IDevice;

  constructor({
    wsBrokerClient,
    sessionManager,
    metrics,
    logger,
    device,
  }: AuthenticationProps) {
    this.sessionManager = sessionManager;
    this.metrics = metrics;
    this.wsBrokerClient = wsBrokerClient;
    this.logger = logger;
    this.device = device;
  }

  async authenticate(
    authRequest: IAuthenticateRequest,
    authStep?: IAuthRelativePath
  ) {
    this.sessionManager.set({ sessionState: SessionState.AUTHENTICATE });
    const timedAuthMetric = this.metrics.embark(Operation.Authenticate);
    const regCode = this.sessionManager.get('registrationCode');
    const region = this.sessionManager.get('region');
    const sessionId = authRequest.SessionContext.SessionId;
    if (!regCode || !sessionId || !region) {
      this.logger.fatal(
        `Illegal invocation of UserAuthentication with inputs regCode:${regCode} sessionId:${sessionId} and region:${region?.endpoint}`
      );
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
        !regCode || !region
          ? WsErrorCodes.ERROR_INVALID_REG_CODE
          : WsErrorCodes.ERROR_INVALID_SESSION_ID,
        !regCode || !region
          ? ClientErrorCode.AuthNoRegCodeFound
          : ClientErrorCode.AuthNoSessionIdFound
      );
    }

    return await this.wsBrokerClient
      .authenticate(regCode, region, authRequest)
      .then((response) => {
        const data: Partial<SessionDetails> = {
          directoryId: response.AuthProviderInfo?.DirectoryId,
          domainName: response.AuthProviderInfo?.DomainName,
          sessionContext: response.SessionContext,
          nextAuthMethod: response?.NextAuthMethods?.[0],
          fallbackAuthMethod: response?.FallbackAuthMethod,
        };
        const authBlobs = this.authBlobFromAuthResponse(
          response.AuthProviderInfo?.AuthBlobs
        );
        if (authBlobs) {
          data.authBlob = authBlobs;
          this.logger.info('Received AuthBlob from Authenticate response');
        }
        if (!this.skipAuthCodeUpdate(authStep)) {
          data.authToken = response.AuthToken;
        }
        if (this.device.getIsOemHandshaked())
          rtcchannel?.brokerAuthenticateResult(true);
        this.sessionManager.set(data);
        this.metrics.emitMetricOperation(timedAuthMetric);
        return response;
      })
      .catch((error) => {
        this.logger.error('WSBrokerClient::Authenticate failed with ', error);
        if (this.device.getIsOemHandshaked())
          rtcchannel?.brokerAuthenticateResult(false);
        this.metrics.emitMetricOperation(timedAuthMetric, error);
        if (error instanceof WsError) {
          throw error;
        } else {
          throw new WsError(
            WsErrorTypes.ERROR_TYPE_AUTHENTICATION,
            WsErrorCodes.ERROR_CM_REQUEST_FAILED
          );
        }
      });
  }

  async fetchAuthInfo(regCode?: string, region?: IRegion) {
    if (!regCode || !region) {
      throw new WsError(
        WsErrorTypes.ERROR_TYPE_REGISTRATION,
        WsErrorCodes.ERROR_INVALID_REG_CODE
      );
    }

    const timedAuthInfoMetric = this.metrics.embark(Operation.GetAuthInfo);
    const clientVersion = this.device.getProductVersion() as string;
    const clientName = this.device.getPlatformAppName();
    const platformType = this.device.getPlatform() as PlatformType;
    const authCapabilities = this.device.getAuthCapabilities(region);
    const { codeVerifier, codeChallenge } =
      await CryptoUtils.generatePkceCodes();
    return await this.wsBrokerClient
      .fetchAuthInfo(
        regCode,
        RegionResolver.isConnectionAlias(regCode),
        region,
        clientVersion,
        clientName,
        platformType,
        authCapabilities,
        codeChallenge
      )
      .then((response) => {
        this.logger.info(
          `Retrieved auth info for ${regCode} and sessionId ${response?.SessionContext?.SessionId} and provider ${response?.SessionContext?.AuthProvider} `
        );
        // TODO: This would require a bit of refactoring. Will bring it in a new commit.
        if (this.device.getIsOemHandshaked()) {
          const url = response.AuthMethods[0].Url;
          const builder = new WarpDriveExternalNativeAuthUrlBuilder(url);
          builder.setRedirectUri('warpdrive');
          builder.setLocale();
          builder.setCsrfToken();
          rtcchannel?.displayWarpDriveUrl(builder.getUrl().href);
        }
        // Update sessionInfo before emitting metrics
        this.sessionManager.set({
          codeVerifier,
          sessionContext: response.SessionContext,
          nextAuthMethod: response.AuthMethods[0],
          primaryAuthMethod: response.AuthMethods[0],
          domainProperties: response.DomainProperties,
        });
        this.metrics.emitMetricOperation(timedAuthInfoMetric);
        return response;
      })
      .catch((error) => {
        this.logger.error(`Retrieved auth info for ${regCode}`, error);
        this.metrics.emitMetricOperation(timedAuthInfoMetric, error);
        if (error instanceof WsError) {
          throw error;
        } else {
          throw new WsError(
            WsErrorTypes.ERROR_TYPE_WARPDRIVE,
            WsErrorCodes.ERROR_CM_REQUEST_FAILED
          );
        }
      });
  }

  private skipAuthCodeUpdate(authStep?: IAuthRelativePath) {
    return authStep === 'reauthenticate';
  }

  private authBlobFromAuthResponse(
    authBlobs: Record<string, string> | undefined
  ): string | undefined {
    return authBlobs && Object.values(authBlobs)[0];
  }
}
